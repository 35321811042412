<template>
  <div class="blog-label" :class="labels[label].class">
    <p class="blog-label__name">
      {{ _T(labels[label].name) }}
    </p>
  </div>
</template>

<script setup>
defineProps({
  label: { type: String, required: true },
});

const labelType = {
  articles: "articles",
  overviews: "overviews",
  news: "news",
};

const labels = {
  [labelType.articles]: {
    name: "@blog label article",
    class: "articles",
  },
  [labelType.overviews]: {
    name: "@blog label review",
    class: "overviews",
  },
  [labelType.news]: {
    name: "@blog navigation news",
    class: "news",
  },
};
</script>

<style lang="scss" scoped>
.blog-label {
  @include flex-container(row, center, center);

  border-radius: 30px;

  padding: 4px 16px;

  &__name {
    @include font(12, 16);
    letter-spacing: 0.02em;
  }
}

.articles {
  background-color: #e3f06b80;

  & .blog-label__name {
    color: #66b403;
  }
}

.overviews {
  background-color: #ff5e6780;

  & .blog-label__name {
    color: #ff5e67;
  }
}

.news {
  background-color: #0758a680;

  & .blog-label__name {
    color: #0758a6;
  }
}
</style>
